import DOMPurify from 'dompurify';
import type { Config } from 'dompurify';

const config: Config = {
  FORBID_ATTR: ['style']
};

DOMPurify.setConfig(config);

export function htmlSanitize<TrustedTypes extends boolean = true>(
  input: string,
  trustedTypes: TrustedTypes = true as TrustedTypes
  // eslint-disable-next-line no-undef
): TrustedTypes extends true ? TrustedHTML | string : string {
  return DOMPurify.sanitize(input, {
    RETURN_TRUSTED_TYPE: trustedTypes
  });
}
