import { API_BASE_URL } from '@config/constants';
import { Api } from '@api/Api';
import { nanoid } from 'nanoid';
import axios from 'axios';

export const apiClient = new Api({
  baseURL: API_BASE_URL
});

let tokenGenerator = undefined as
  | ((ignoreCache?: boolean) => Promise<string>)
  | undefined
  | null;

export function setTokenGenerator(
  generator: ((ignoreCache?: boolean) => Promise<string>) | null
) {
  tokenGenerator = generator;
}

let logoutHandler = undefined as (() => void) | undefined | null;

export function setLogoutHandler(handler: (() => void) | null) {
  logoutHandler = handler;
}

let networkErrorHandler = undefined as
  | ((isNetworkError: boolean) => void)
  | undefined
  | null;

export function setNetworkErrorHandler(
  handler: (isNetworkError: boolean) => void | null
) {
  networkErrorHandler = handler;
}

apiClient.instance.interceptors.response.use(undefined, (err) => {
  if (axios.isCancel(err)) throw err;
  if (!axios.isAxiosError(err)) throw err;
  if (!err.config) throw err;
  const requestMethod = err.config.method?.toUpperCase();
  const requestUrl = err.config.url;
  err.message = `[${requestMethod}] ${requestUrl}: ${err.message}`;
  throw err;
});

apiClient.instance.interceptors.response.use(undefined, (err) => {
  const { config, response } = err;
  const isNetworkError = !response && !axios.isCancel(err);
  const isAuthError = response?.status === 401;
  const retry = isAuthError ? 1 : config?.retry ?? 0;
  const attempt = config?.attempt ?? 0;
  const attempsLeft = Math.max(0, retry - attempt);
  const needRetry = attempsLeft > 0 && (isNetworkError || isAuthError);

  if (!needRetry) {
    if (isNetworkError && networkErrorHandler) {
      console.warn('Network error');
      networkErrorHandler(true);
    }
    if (isAuthError && logoutHandler) {
      console.warn('Logout due to auth error');
      logoutHandler();
    }
    return Promise.reject(err);
  }

  const minWaitSeconds = 2;
  const maxWaitSeconds = 15;
  const exponentialRate = 2;
  const waitSeconds =
    Math.min(maxWaitSeconds, minWaitSeconds * exponentialRate ** attempt) +
    Math.random();
  console.log(
    `Retrying ${config?.method} request ${config?.url} in ${waitSeconds}s`
  );
  config.attempt = attempt + 1;
  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => resolve(), waitSeconds * 1000);
  });
  return delayRetryRequest.then(() =>
    apiClient.instance({
      ...config,
      ignoreAccessTokenCache: isAuthError
    })
  );
});

apiClient.instance.interceptors.request.use(async (config) => {
  const ignoreAccessTokenCache = !!(config as any).ignoreAccessTokenCache;
  const token = tokenGenerator
    ? await tokenGenerator(ignoreAccessTokenCache)
    : undefined;
  if (!token) return config;
  config.headers.set('authorization', `Bearer ${token}`);
  return {
    ...config,
    retry: (config as any).retry ?? 3,
    attempt: (config as any).attempt ?? 0
  };
});

export const last100CorrelationIds: string[] = [];

apiClient.instance.interceptors.request.use(async (config) => {
  const actionMethods = ['post', 'put', 'patch', 'delete'];
  if (!config.method || !actionMethods.includes(config.method.toLowerCase())) {
    return config;
  }
  const correlationId = nanoid();
  if (last100CorrelationIds.length > 100) {
    last100CorrelationIds.shift();
  }
  last100CorrelationIds.push(correlationId);
  config.headers.set('x-correlation-id', correlationId);
  return config;
});
