import { apiClient } from '@api/client';
import { AUTH0_DOMAIN } from '@config/constants';
import axios from 'axios';
import {
  ProfileControllerGetPictureUploadParamsParams,
  UpdatePersonalInfoDto,
  RequestParams,
  TwoStepVerifyDto,
  ChangeProfilePasswordDto,
  ProfileControllerGetNotificationPreferencesParams,
  UpdateNotificationPreferencesDto,
  ResendVerificationMailDto,
  CompleteOnboardingStepDto
} from '@api/Api';

export const resetUserPassword = async ({
  password,
  confirmPassword
}: {
  password: string;
  confirmPassword: string;
}) => {
  let data = {};
  document
    .querySelectorAll('[data-auth0-reset-password-values]')
    .forEach((el) => {
      data = {
        ...data,
        [(el as any).name]: (el as any).value
      };
    });
  data = {
    ...data,
    newPassword: password,
    confirmNewPassword: confirmPassword
  };
  try {
    const result = await axios.request({
      method: 'POST',
      url: `https://${AUTH0_DOMAIN}/lo/reset`,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      data: new URLSearchParams(data).toString()
    });
    return result;
  } catch (e) {
    return e;
  }
};

export const profileChangePassword = (params: ChangeProfilePasswordDto) =>
  apiClient.profile.profileControllerChangePassword(params);

export const profileDelete = () => apiClient.profile.profileControllerDelete();

export const getAvailableProviders = (email: string) =>
  apiClient.profile.profileControllerGetAvailableProviders({ email });

export const resendMailVerificationCode = () =>
  apiClient.profile.profileControllerResendVerificationMail();

export const verifyEmail = (code: string) =>
  apiClient.profile.profileControllerVerifyEmail({ code });

export const uploadPictureParams = (
  params: ProfileControllerGetPictureUploadParamsParams
) => apiClient.profile.profileControllerGetPictureUploadParams(params);

export const updatePersonalInfo = (params: UpdatePersonalInfoDto) =>
  apiClient.profile.profileControllerUpdateProfile(params);

export const removePicture = () =>
  apiClient.profile.profileControllerDeletePicture();

export const resendTwoStepVerificationCode = (
  data: ResendVerificationMailDto,
  params: RequestParams = {}
) =>
  apiClient.profile.profileControllerResendTwoStepVerificationMail(
    data,
    params
  );

export const twoStepVerify = (data: TwoStepVerifyDto, params: RequestParams) =>
  apiClient.profile.profileControllerTwoStepVerify(data, params);

export const enableTwoStepVerification = (data: TwoStepVerifyDto) =>
  apiClient.profile.profileControllerEnableTwoStepVerification(data);

export const disableTwoStepVerification = (data: TwoStepVerifyDto) =>
  apiClient.profile.profileControllerDisableTwoStepVerification(data);

export const getNotificationPreferences = (
  data: ProfileControllerGetNotificationPreferencesParams
) => apiClient.profile.profileControllerGetNotificationPreferences(data);

export const updateNotificationPreferences = (
  data: UpdateNotificationPreferencesDto
) => apiClient.profile.profileControllerUpdateNotificationPreferences(data);

export const getKnockUserToken = () =>
  apiClient.profile.profileControllerGetKnokUserToken();

export const completeOnboardingStep = (data: CompleteOnboardingStepDto) =>
  apiClient.profile.profileControllerCompleteOnboardingStep(data);
