/* eslint-disable global-require */
import React, { useRef, useEffect, memo, useState } from 'react';
import lottieLight, {
  AnimationItem
} from 'lottie-web/build/player/lottie_light';

type ViewType =
  | 'audio'
  | 'pause'
  | 'circle'
  | 'check'
  | 'success'
  | 'addMediaInFolder'
  | 'addCampaign'
  | 'addShareLink'
  | 'paperClip'
  | 'arrow'
  | 'preloader'
  | 'noSearchingResult'
  | 'signIn'
  | 'star'
  | 'upload'
  | 'click'
  | 'confetti';

type LottieComponentProps = {
  view: ViewType;
  className?: string;
  isStopped?: boolean;
  autoPlay?: boolean;
  isLooped?: boolean;
  progress?: number;
};

export default memo(function LottieComponent({
  view,
  className = undefined,
  isStopped = false,
  autoPlay = true,
  isLooped = true,
  progress = 0
}: LottieComponentProps) {
  const lottie = useRef<AnimationItem>();
  const [icon, setIcon] = useState<unknown>(undefined);

  const ref = useRef<HTMLDivElement>(null);
  const autoPlayRef = useRef(autoPlay);
  const isLoopedRef = useRef(isLooped);

  autoPlayRef.current = autoPlay;
  isLoopedRef.current = isLooped;

  useEffect(() => {
    (async () => {
      const { default: icon } = await import(
        `./utils/${view}.${view === 'confetti' ? 'json' : 'ts'}`
      );
      setIcon(await icon);
    })();
  }, [view]);

  useEffect(() => {
    if (!lottie.current) return;
    if (isStopped) {
      lottie.current.goToAndStop(0);
    } else lottie.current.play();
  }, [isStopped]);

  useEffect(() => {
    if (!lottie.current || !progress) return;
    const frames = lottie.current.getDuration(true) / 100;
    lottie.current.goToAndStop(frames * progress, true);
  }, [progress]);

  useEffect(() => {
    if (!icon || !ref.current) return;
    ref.current.innerHTML = '';
    lottie.current = lottieLight.loadAnimation({
      renderer: 'svg',
      loop: isLoopedRef.current,
      autoplay: autoPlayRef.current,
      animationData: icon,
      container: ref.current
    });
  }, [icon]);

  return <div ref={ref} className={className} />;
});
