import { ReactComponent as GmailSvg } from '@assets/icons/gmail.svg';
import { ReactComponent as OutlookSvg } from '@assets/icons/outlook.svg';
import { ReactComponent as YahooSvg } from '@assets/icons/yahoo.svg';

import { IEmailService, IEmailServiceListItem } from './auth.types';

const emailServices: Record<string, IEmailServiceListItem> = {
  outlook: {
    title: 'Outlook',
    path: OutlookSvg,
    link: 'https://outlook.live.com'
  },
  gmail: { title: 'Gmail', path: GmailSvg, link: 'https://mail.google.com' },
  yahoo: { title: 'Yahoo', path: YahooSvg, link: 'https://www.yahoo.com' },
  myyahoo: { title: 'My Yahoo', path: YahooSvg, link: 'https://my.yahoo.com' }
};

export const passwordValidationRegExp =
  /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W)(?=.{8,})/;

const passwordValidationList = [
  { valid: false, regExp: /(?=.*[A-Z])/, copy: 'One uppercase letter' },
  { valid: false, regExp: /(?=.*[a-z])/, copy: 'One lowercase letter' },
  { valid: false, regExp: /(?=.*\d)/, copy: 'One number' },
  { valid: false, regExp: /(?=.*\W)/, copy: 'One special character' },
  { valid: false, regExp: /(?=.{8,})/, copy: '8+ characters' }
];

export const chooseEmailService = (email: string): IEmailService => {
  let service: IEmailService = {
    service: '',
    Logo: null,
    link: ''
  };

  Object.keys(emailServices).forEach((key) => {
    if (email.includes(`@${key.toLocaleLowerCase()}`)) {
      service = {
        service: emailServices[key].title,
        Logo: emailServices[key].path,
        link: emailServices[key].link
      };
    }
  });
  if (!service.Logo)
    service = {
      service: 'Gmail',
      Logo: GmailSvg,
      link: 'https://mail.google.com'
    };

  return service;
};

export const passwordValidator = (password: string) => {
  return passwordValidationList.map((el) => {
    if (el.regExp.test(password)) {
      return {
        ...el,
        valid: true
      };
    }
    return el;
  });
};
