import { nonNull } from '@helpers/non-null';

export enum AUTHORIZATION_PROVIDERS {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE'
}

export const NODE_ENV = nonNull(process.env.NODE_ENV);
export const APP_BASE_URL = nonNull(
  process.env.REACT_APP_APP_BASE_URL,
  'REACT_APP_APP_BASE_URL'
)
  .split(',')
  .map((x) => x.trim());
export const API_BASE_URL = nonNull(
  process.env.REACT_APP_API_BASE_URL,
  'REACT_APP_API_BASE_URL'
);
export const WEBSOCKET_URL = nonNull(
  process.env.REACT_APP_WEBSOCKET_URL,
  'REACT_APP_WEBSOCKET_URL'
);

export const AUTH0_CLIENT_ID = nonNull(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  'REACT_APP_AUTH0_CLIENT_ID'
);
export const AUTH0_DOMAIN = nonNull(
  process.env.REACT_APP_AUTH0_DOMAIN,
  'REACT_APP_AUTH0_DOMAIN'
);
export const AUTH0_AUDIENCE = nonNull(
  process.env.REACT_APP_AUTH0_AUDIENCE,
  'REACT_APP_AUTH0_AUDIENCE'
);
export const MIXPANEL_KEY = nonNull(
  process.env.REACT_APP_MIXPANEL_KEY,
  'env.REACT_APP_MIXPANEL_KEY'
);
export const KNOCK_PUBLIC_KEY = nonNull(
  process.env.REACT_APP_KNOCK_PUBLIC_KEY,
  'REACT_APP_KNOCK_PUBLIC_KEY'
);
export const KNOCK_FEED_ID = nonNull(
  process.env.REACT_APP_KNOCK_FEED_ID,
  'REACT_APP_KNOCK_FEED_ID'
);
export const GOOGLE_GTAG_ID = nonNull(
  process.env.REACT_APP_GOOGLE_GTAG_ID,
  'REACT_APP_GOOGLE_GTAG_ID'
);
